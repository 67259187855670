import React, { useEffect, useState } from 'react';
import {
  Box,
  CallToActionButton,
  Container,
  Divider,
  FlexBox,
  Panel,
  Spacer,
  StandByOverlay
} from '@directsoftware/ui-kit-web-admin';
import axios from 'axios';
import moment from 'moment/moment';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { isInclusivelyBeforeDay } from 'react-dates';
import { isNull } from 'lodash';
import {
  selectCheckoutState,
  updateCheckoutSlice
} from '../../redux/slices/checkout';
import { selectUiState, updateUiSlice } from '../../redux/slices/ui';
import CheckoutSteps from './shared/checkout-steps';
import TripDetails from './shared/trip-details';
import { updateQueryString } from '../listings/resources/shared-functions';
import ExtraAddons from './shared/extra-addons';
import ContactInfo from './shared/contact-info';
import ContactAddress from './shared/contact-address';
import StripeV2 from '../stripe/stripe-v2';
import FooterFinal from '../layout/footer-final';
import CheckoutListingInfo from './shared/checkout-listing-info';
import CheckoutPricingInfo from './shared/checkout-pricing-info';
import { useDetectMobile } from '../../../shared/hooks/useDetectMobile';
import CheckoutMobilePricing from './shared/checkout-mobile-pricing';
import CheckoutLeftSkeleton from './skeletons/checkout-left-skeleton';
import CheckoutRightSkeleton from './skeletons/checkout-right-skeleton';
import Meta from './meta';
import LynnbrookPaymentV2 from '../lynnbrook/lynnbrook-payment-v2';
import CheckoutGuestInfoCollapsedV2 from './shared/checkout-guest-info-collapsed-v2';

const CheckoutV2 = props => {
  const { isLargeTabletOrGreater } = useDetectMobile();
  const dispatch = useDispatch();
  const brand = useSelector(state => state.brand);
  const checkoutState = useSelector(selectCheckoutState);
  const { checkoutStateChanged } = useSelector(selectUiState);
  const [isLynnbrook, setIsLynnbrook] = useState(false);

  const updateCheckoutState = (data, isFilterChange = false) => {
    dispatch(updateCheckoutSlice(data));
    if (isFilterChange) dispatch(updateUiSlice({ checkoutStateChanged: true }));
  };

  const parseCheckInCheckOut = () => {
    const parsedQuery = queryString.parse(location.search);
    const parsedCheckInDate = moment(
      parsedQuery['check-in'],
      'DD-MM-YYYY'
    ).format('DD-MM-YYYY');
    const parsedCheckOutDate = moment(
      parsedQuery['check-out'],
      'DD-MM-YYYY'
    ).format('DD-MM-YYYY');

    return { check_in: parsedCheckInDate, check_out: parsedCheckOutDate };
  };

  const checkPricing = () => {
    const parsedQuery = queryString.parse(location.search);
    const { guests, deliveryLocation, customLocation } = parsedQuery;

    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/unit_pricing/${
          checkoutState.quoteId
        }`,
        {
          headers: { 'Content-Type': 'application/json' },
          params: {
            quote_id: checkoutState.quoteId,
            addon_fee_ids: checkoutState.addonFeeIds,
            coupon_code: checkoutState.couponCode,
            listing_id: checkoutState.listing.id,
            booking_range: JSON.stringify(checkoutState.bookingDaysInclusive),
            num_guests: guests,
            delivery_location: customLocation
              ? sessionStorage.customLocation
              : deliveryLocation
          }
        }
      )
      .then(response => {
        const data = response.data;
        updateCheckoutState({
          fees: data.fees,
          pricing: data,
          availabilityLoading: false,
          checkoutTotal: data.total
        });
      })
      .catch(error => {
        console.warn(error);
      });
  };

  const addCouponCode = code => {
    updateCheckoutState({ couponCode: code, checkoutCheckPricing: true }, true);
  };

  const verifyCouponCode = () => {
    if (isNull(checkoutState.allCouponCodes)) {
      updateCheckoutState({ badCode: true });
    } else if (
      checkoutState.allCouponCodes &&
      checkoutState.allCouponCodes.includes(checkoutState.couponCode)
    ) {
      addCouponCode(checkoutState.couponCode);
    } else {
      updateCheckoutState({ badCode: true });
    }
  };

  const fetchCouponCodes = () => {
    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/fetch_coupon_codes/${
          checkoutState.listing.id
        }`,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then(res => {
        const data = res.data?.data ? res.data.data : res.data;
        updateCheckoutState({
          allCouponCodes: data
        });
      })
      .catch(error => {
        console.warn(error);
      });
  };

  const checkAvailability = () => {
    if (isNull(checkoutState.unit.room_type_id)) {
      axios
        .get(
          `${process.env.DIRECT_URL}/api/v2/listings/single/${
            checkoutState.listing.id
          }/availability`,
          {
            headers: { 'Content-Type': 'application/json' },
            context: this,
            params: {
              unit_id: checkoutState.unit.id,
              booking_range: JSON.stringify(checkoutState.bookingDaysInclusive),
              guests: checkoutState.guests
            }
          }
        )
        .then(response => {
          const data = response.data;
          updateCheckoutState({
            availability: data
          });
          if (data.bookable) {
            fetchCouponCodes();
            verifyCouponCode();
            checkPricing();
          }
        })
        .catch(error => {
          console.warn(error);
        });
    } else if (checkoutState.listing.vehicle_id) {
      axios
        .get(
          `${process.env.DIRECT_URL}/api/v2/listings/single/${
            checkoutState.listing.id
          }/availability`,
          {
            headers: { 'Content-Type': 'application/json' },
            context: this,
            params: {
              vehicle_id: checkoutState.unit.id,
              booking_range: JSON.stringify(checkoutState.bookingDaysInclusive),
              guests: checkoutState.guests
            }
          }
        )
        .then(response => {
          const data = response.data;
          updateCheckoutState({
            availability: data
          });
          if (data.bookable) {
            fetchCouponCodes();
            verifyCouponCode();
            checkPricing();
          }
        })
        .catch(error => {
          console.warn(error);
        });
    } else {
      axios
        .get(
          `${process.env.DIRECT_URL}/api/v2/listings/room/${
            checkoutState.listing.id
          }/room_type_availability?booking_range=${JSON.stringify(
            checkoutState.bookingDaysInclusive
          )}&unit_id=${checkoutState.unit.id}&guests=${checkoutState.guests}`,
          {
            headers: { 'Content-Type': 'application/json' }
          }
        )
        .then(response => {
          updateCheckoutState({
            availability: response.data
          });
          if (response.data.bookable) {
            fetchCouponCodes();
            verifyCouponCode();
            checkPricing();
          }
        })
        .catch(error => {
          console.warn(error);
        });
    }
  };

  const parseUrl = () => {
    const parsedQuery = queryString.parse(location.search);

    if (parsedQuery['check-in'] && parsedQuery['check-out']) {
      const parsedCheckInDate = moment(parsedQuery['check-in'], 'DD-MM-YYYY');
      const parsedCheckOutDate = moment(parsedQuery['check-out'], 'DD-MM-YYYY');
      let guests = parsedQuery.guests;

      if (isNaN(guests) || guests < 1) {
        guests = 1;
      } else if (guests > checkoutState.unit.num_sleep) {
        guests = checkoutState.unit.num_sleep;
      }

      const bookingDaysInclusive = [];
      const d = parsedCheckInDate.clone();

      while (isInclusivelyBeforeDay(d, parsedCheckOutDate)) {
        bookingDaysInclusive.push({
          key: d.format('DD-MM-YYYY'),
          day: d.day()
        });
        d.add(1, 'days');
      }

      const quoteId = parsedQuery.quote_id;
      updateCheckoutState(
        {
          checkInDate: moment(parsedCheckInDate),
          checkInDateRaw: parsedQuery['check-in'],
          checkOutDate: moment(parsedCheckOutDate),
          bookingDaysInclusive,
          bookingLength: bookingDaysInclusive.length - 1,
          guests,
          datesParsed: true,
          addonFeeIds: checkoutState.addonFeeIds,
          quoteId: quoteId || checkoutState.quoteIdFromDb,
          checkoutCheckAvailability: true
        },
        true
      );
    } else {
      updateCheckoutState({
        datesParsed: false,
        isAvailable: false
      });
    }
  };

  const fetchBookingInfo = () => {
    const parsedQuery = queryString.parse(location.search);
    const {
      couponCode,
      guests,
      customLocation,
      deliveryLocation
    } = parsedQuery;
    const parsedDates = parseCheckInCheckOut();

    updateCheckoutState({ couponCode });
    axios
      .post(`/api/bookings/checkout/${props.match.params.id}`, {
        headers: { 'Content-Type': 'application/json' },
        check_in: parsedDates.check_in,
        check_out: parsedDates.check_out,
        num_guests: guests,
        coupon_code: couponCode
      })
      .then(response => {
        const data = response.data;
        updateCheckoutState(
          {
            brandCurrency: data.brand_currency,
            checkoutTotal: data.checkout_total,
            contractTermsAndConditions: data.contract_terms_and_conditions,
            deposits: data.deposits,
            delivery_locations: data.delivery_locations,
            deliveryLocation: customLocation
              ? sessionStorage.customLocation
              : deliveryLocation,
            featuredImage: data.featured_image,
            fees: data.fees,
            listing: data.listing,
            location: data.location,
            obfuscatedAddress: data.obfuscated_address,
            property: data.property,
            quoteIdFromDb: data.quote_id,
            rentalAgreement: data.rental_agreement,
            requiredAge: data.required_age,
            slug: data.slug,
            stripePublishableKey: data.stripe_publishable_key,
            stripeCustomerId: data.stripe_customer_id,
            stripeIntentId: data.stripe_intent_id,
            unit: data.unit,
            verifyAddress: data.verify_address,
            verifyAge: data.verify_age,
            verifyImage: data.verify_image,
            verifyImageDescription: data.verify_image_description,
            verifySignature: data.verify_signature,
            loading: false,
            checkoutParseUrl: true,
            checkoutGetLynnbrookTargetId: true,
            accountPersonCreated: false,
            lynnbrookErrors: [],
            fetchingLynnbrookConfig: false,
            lynnbrookConfig: {
              accountTargetId: null,
              accountPersonId: null,
              sessionId: null,
              token: null
            },
            lynnbrookCardAccepted: false
          },
          true
        );
      });
  };

  const fetchLynbrookConfig = targetId => {
    const name = `${checkoutState.customerFirstName} ${
      checkoutState.customerLastName
    }`;
    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/listings/${
          checkoutState.listing.id
        }/lynnbrook_config?customer_email=${
          checkoutState.customerEmail
        }&customer_name=${name}&customer_telephone=${
          checkoutState.customerTelephone
        }&customer_date_of_birth=${
          checkoutState.customerDateOfBirth
        }&target_id=${targetId}`,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then(res => {
        const data = res.data;
        updateCheckoutState({
          fetchingLynnbrookConfig: false,
          accountPersonCreated: true,
          lynnbrookConfig: {
            ...checkoutState.lynnbrookConfig,
            accountPersonId: data.account_person_id,
            sessionId: data.session_id,
            token: process.env.APTEXX_API_TOKEN
          }
        });
      })
      .catch(error => {
        console.warn(error);
      });
  };

  const fetchLynnbrookTargetId = () => {
    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/listings/${
          checkoutState.listing.id
        }/target_id`,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then(res => {
        updateCheckoutState({
          lynnbrookConfig: {
            ...checkoutState.lynnbrookConfig,
            accountTargetId: res.data.target_id
          }
        });
      })
      .catch(error => {
        console.warn(error);
      });
  };

  const checkFieldIsInvalid = field => {
    return field === '' || field === undefined || !field;
  };

  const checkAuthenticate = () => {
    if (brand.organization.integrations) {
      const authenticate = brand.organization.integrations.filter(
        integration => integration.integration_name === 'authenticate'
      )[0];

      if (
        authenticate &&
        authenticate.additional_integration_details &&
        authenticate.additional_integration_details.medallion_workflow_id
      ) {
        return true;
      }
    }
    return false;
  };

  const createAccountPerson = () => {
    const {
      customerFirstName,
      customerLastName,
      customerEmail,
      customerTelephone,
      customerDateOfBirth,
      adrStreet,
      adrCity,
      adrState,
      adrPostalCode,
      adrCountry
    } = checkoutState;

    const errorArray = [];

    if (checkFieldIsInvalid(customerFirstName))
      errorArray.push('customerFirstName');
    if (checkFieldIsInvalid(customerLastName))
      errorArray.push('customerLastName');
    if (
      checkFieldIsInvalid(customerEmail) ||
      checkoutState.validationErrors.length > 0
    )
      errorArray.push('customerEmail');
    if (checkFieldIsInvalid(customerTelephone))
      errorArray.push('customerTelephone');
    if (checkAuthenticate() && checkFieldIsInvalid(customerDateOfBirth))
      errorArray.push('customerDateOfBirth');
    if (checkFieldIsInvalid(adrStreet)) errorArray.push('adrStreet');
    if (checkFieldIsInvalid(adrCity)) errorArray.push('adrCity');
    if (checkFieldIsInvalid(adrState)) errorArray.push('adrState');
    if (checkFieldIsInvalid(adrPostalCode)) errorArray.push('adrPostalCode');
    if (checkFieldIsInvalid(adrCountry)) errorArray.push('adrCountry');

    updateCheckoutState({ lynnbrookErrors: errorArray });

    if (errorArray.length === 0) {
      updateCheckoutState({ fetchingLynnbrookConfig: true });
      fetchLynbrookConfig(checkoutState.lynnbrookConfig.accountTargetId);
    }
  };

  useEffect(
    () => {
      if (checkoutStateChanged) {
        dispatch(updateUiSlice({ checkoutStateChanged: false }));
        if (checkoutState.checkoutGetLynnbrookTargetId && isLynnbrook) {
          updateCheckoutState({ checkoutGetLynnbrookTargetId: false });
          fetchLynnbrookTargetId();
        }
        if (checkoutState.updateUrl) {
          updateCheckoutState(
            { updateUrl: false, checkoutParseUrl: true },
            true
          );
          updateQueryString(checkoutState);
        }
        if (checkoutState.checkoutParseUrl) {
          updateCheckoutState({ checkoutParseUrl: false });
          parseUrl();
        }
        if (
          checkoutState.checkoutCheckAvailability &&
          checkoutState.bookingDaysInclusive
        ) {
          updateCheckoutState({ checkoutCheckAvailability: false });
          checkAvailability();
        }
        if (checkoutState.checkoutCheckPricing) {
          updateCheckoutState({ checkoutCheckPricing: false });
          checkPricing();
        }
      }
    },
    [checkoutState, checkoutStateChanged]
  );

  useEffect(() => {
    fetchBookingInfo();
    dispatch(updateUiSlice({ checkoutActiveStep: 'stepOne' }));
    setIsLynnbrook(brand.organization.payment_processor === 2);
  }, []);

  return !checkoutState.loading ? (
    <>
      <Meta />
      <Container variation="extended-wls">
        <CheckoutSteps />
        <FlexBox className="detailsLayout checkoutLayout">
          <Box flex="1">
            {checkoutState.availability &&
            checkoutState.availability.bookable &&
            checkoutState.pricing ? (
              <>
                <TripDetails updateCheckoutState={updateCheckoutState} />
                <ExtraAddons updateCheckoutState={updateCheckoutState} />
                {!isLargeTabletOrGreater && (
                  <CheckoutMobilePricing
                    updateCheckoutState={updateCheckoutState}
                  />
                )}
                {isLynnbrook ? (
                  <>
                    {!checkoutState.accountPersonCreated ? (
                      <>
                        <ContactInfo
                          updateCheckoutState={updateCheckoutState}
                          isLynnbrook
                          isAuthenticate={checkAuthenticate()}
                        />
                        <ContactAddress
                          stateObject={checkoutState}
                          updateCheckoutState={updateCheckoutState}
                        />
                        <CallToActionButton
                          onClick={() => {
                            createAccountPerson();
                          }}
                          size="large"
                          isFullWidth
                          isDisabled={checkoutState.fetchingLynnbrookConfig}
                          customButtonColor={
                            brand.brand_info.colors?.color_primary
                          }
                          customTextColor={
                            brand.brand_info.colors?.color_primary_text
                          }
                        >
                          Continue
                        </CallToActionButton>
                        <Spacer size="l" />
                        <Divider />
                        <Spacer size="l" />
                      </>
                    ) : (
                      <CheckoutGuestInfoCollapsedV2
                        editOnClick={() =>
                          updateCheckoutState({ accountPersonCreated: false })
                        }
                      />
                    )}
                  </>
                ) : (
                  <>
                    <ContactInfo updateCheckoutState={updateCheckoutState} />
                    <ContactAddress
                      stateObject={checkoutState}
                      updateCheckoutState={updateCheckoutState}
                    />
                  </>
                )}
                {isLynnbrook ? (
                  <LynnbrookPaymentV2
                    useState={updateCheckoutState}
                    fetchLynbrookConfig={fetchLynbrookConfig}
                    addonFeeIds={checkoutState.addonFeeIds}
                    availability={checkoutState.availability}
                    booking={props.booking}
                    bookingDaysInclusive={checkoutState.bookingDaysInclusive}
                    brand_info={brand.brand_info}
                    checkInDate={checkoutState.checkInDate}
                    checkOutDate={checkoutState.checkOutDate}
                    couponCode={checkoutState.couponCode}
                    customerEmail={checkoutState.customerEmail}
                    customerName={`${checkoutState.customerFirstName} ${
                      checkoutState.customerLastName
                    }`}
                    customerPostalCode={checkoutState.adrPostalCode}
                    customerTelephone={checkoutState.customerTelephone}
                    customerDateOfBirth={checkoutState.customerDateOfBirth}
                    isAuthenticate={checkAuthenticate()}
                    guests={checkoutState.guests}
                    listing={checkoutState.listing}
                    max_guests={checkoutState.unit.num_sleep}
                    pricing={checkoutState.pricing}
                    quoteId={checkoutState.quoteId}
                    rental_agreement={checkoutState.rentalAgreement}
                    slug={checkoutState.slug}
                    stripeCustomerId={checkoutState.stripeCustomerId}
                    stripeIntentId={checkoutState.stripeIntentId}
                    stripePublishableKey={checkoutState.stripePublishableKey}
                    unit={checkoutState.unit}
                    updateGuests={guests => {
                      updateCheckoutState(
                        {
                          availability: null,
                          bookingType: null,
                          pricing: null,
                          guests,
                          updateUrl: true
                        },
                        true
                      );
                    }}
                    verifyImage={checkoutState.verifyImage}
                    verifySignature={checkoutState.verifySignature}
                    verifyAge={checkoutState.verifyAge}
                    verifyAddress={checkoutState.verifyAddress}
                    updateCheckoutState={updateCheckoutState}
                    urlLocation={props.location}
                    validationErrors={checkoutState.validationErrors}
                  />
                ) : (
                  <StripeV2
                    addonFeeIds={checkoutState.addonFeeIds}
                    availability={checkoutState.availability}
                    booking={props.booking}
                    bookingDaysInclusive={checkoutState.bookingDaysInclusive}
                    brand_info={brand.brand_info}
                    brand={brand}
                    checkInDate={checkoutState.checkInDate}
                    checkOutDate={checkoutState.checkOutDate}
                    couponCode={checkoutState.couponCode}
                    customerEmail={checkoutState.customerEmail}
                    customerName={checkoutState.customerName}
                    customerPostalCode={checkoutState.adrPostalCode}
                    customerTelephone={checkoutState.customerTelephone}
                    deliveryLocation={checkoutState.deliveryLocation}
                    adrStreet={checkoutState.adrStreet}
                    adrUnit={checkoutState.adrUnit}
                    adrCity={checkoutState.adrCity}
                    adrState={checkoutState.adrState}
                    adrCountry={checkoutState.adrCountry}
                    adrPostalCode={checkoutState.adrPostalCode}
                    adrCountryShort={checkoutState.adrCountryShort}
                    guests={checkoutState.guests}
                    listing={checkoutState.listing}
                    max_guests={checkoutState.unit.num_sleep}
                    pricing={checkoutState.pricing}
                    quoteId={checkoutState.quoteId}
                    rental_agreement={checkoutState.rentalAgreement}
                    slug={checkoutState.slug}
                    stripeCustomerId={checkoutState.stripeCustomerId}
                    stripeIntentId={checkoutState.stripeIntentId}
                    stripePublishableKey={checkoutState.stripePublishableKey}
                    unit={checkoutState.unit}
                    updateGuests={guests => {
                      updateCheckoutState(
                        {
                          availability: null,
                          bookingType: null,
                          pricing: null,
                          guests,
                          updateUrl: true
                        },
                        true
                      );
                    }}
                    verifyImage={checkoutState.verifyImage}
                    verifySignature={checkoutState.verifySignature}
                    verifyAge={checkoutState.verifyAge}
                    verifyAddress={checkoutState.verifyAddress}
                    urlLocation={props.location}
                    validationErrors={checkoutState.validationErrors}
                  />
                )}
              </>
            ) : (
              <CheckoutLeftSkeleton />
            )}
          </Box>
          {isLargeTabletOrGreater && (
            <Box className="detailsLayout__right checkoutLayout">
              {!checkoutState.availabilityLoading ? (
                <Panel addShadow>
                  {checkoutState.availability?.bookable &&
                  checkoutState.pricing ? (
                    <>
                      <CheckoutListingInfo />
                      <CheckoutPricingInfo
                        updateCheckoutState={updateCheckoutState}
                      />
                    </>
                  ) : (
                    <Box padding="l">
                      Sorry, this listing is not available for booking at this
                      time.
                    </Box>
                  )}
                </Panel>
              ) : (
                <CheckoutRightSkeleton />
              )}
            </Box>
          )}
        </FlexBox>
      </Container>
      <Spacer size="xxl" />
      <FooterFinal />
    </>
  ) : (
    <StandByOverlay freezeBody={false} reveal={checkoutState.loading} />
  );
};

export default CheckoutV2;
