import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  availabilityLoading: true,
  isStripeLoading: true,
  isStripeSuccessful: false,
  bookingType: null,
  checkInDate: null,
  checkOutDate: null,
  bookingDaysInclusive: null,
  bookingLength: 0,
  datesParsed: null,
  isAvailable: null,
  guests: 1,
  pricing: null,
  transactionError: null,
  addonFeeIds: [],
  brandCurrency: '',
  checkoutTotal: null,
  contractTermsAndConditions: null,
  deposits: [],
  featuredImage: {},
  fees: [],
  listing: {},
  obfuscatedAddress: '',
  property: {},
  rentalAgreement: {},
  requiredAge: null,
  slug: '',
  stripePublishableKey: '',
  stripeCustomerId: '',
  stripeIntentId: '',
  unit: {},
  verifyAddress: null,
  verifyAge: null,
  verifyImage: null,
  verifyImageDescription: '',
  verifySignature: null,
  feeQuantities: [],
  quantity: 0,
  couponCode: '',
  allCouponCodes: null,
  badCode: false,
  checkoutCheckAvailability: false,
  checkoutParseUrl: false,
  checkoutCheckPricing: false,
  adrStreet: null,
  adrUnit: null,
  adrCity: null,
  adrState: null,
  adrCountry: null,
  adrPostalCode: null,
  adrCountryShort: null,
  mapsLoaded: false,
  mapsLoading: true,
  validationErrors: []
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    updateCheckoutSlice: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetCheckoutSlice: state => {
      return { ...state, ...initialState };
    }
  }
});

export const {
  updateCheckoutSlice,
  resetCheckoutSlice
} = checkoutSlice.actions;

export default checkoutSlice.reducer;

export const selectCheckoutState = createSelector(
  state => state.checkout,
  checkout => checkout
);
