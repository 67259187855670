import React from 'react';
import ReactI18n from 'react-i18n';
import ListingsSearchItemPrice from '../src/components/listings/atoms/listings-search-item-price';

const addCommas = numString => numString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

function isNumber(value) {
  return typeof value === 'number';
}

const formatCurrency = (
  value,
  currencyCode,
  locale = undefined,
  noDecimals = false
) => {
  const formatter = new Intl.NumberFormat(locale || undefined, {
    style: 'currency',
    currency: currencyCode || 'USD',
    minimumFractionDigits: noDecimals ? 0 : 2,
    maximumFractionDigits: noDecimals ? 0 : 2
  });

  return formatter.format(value);
};

const getAverage = listing => {
  let totalRating = 0;
  listing.reviews.forEach(review => {
    totalRating += review.rating;
  });
  return (totalRating / listing.reviews.length)
    .toFixed(2)
    .replace(/[.,]00$/, '');
};

const priceUnitCount = listing => {
  if (listing.property.multi_unit) {
    return listing.listings.filter(unit => unit.can_fit_guests).length;
  }
  return listing.listings.length;
};

const renderPrice = (listing, renderAsText = false, currency) => {
  const translate = ReactI18n.getIntlMessage;
  if (listing && listing.listings) {
    const checkPromo =
      listing.listings[0].bookable_nightly_price <
      listing.listings[0].bookable_nightly_price_before_promotion;
    return checkPromo ? (
      <ListingsSearchItemPrice
        beforePromoAmount={
          listing.listings[0].bookable_nightly_price_before_promotion
        }
        amount={listing.listings[0].bookable_nightly_price}
        isMultiUnit={listing.property.multi_unit}
        unitCount={priceUnitCount(listing)}
        translate={translate}
        renderAsText={renderAsText}
        currency={currency}
      />
    ) : (
      <ListingsSearchItemPrice
        amount={listing.listings[0].bookable_nightly_price}
        isMultiUnit={listing.property.multi_unit}
        unitCount={priceUnitCount(listing)}
        translate={translate}
        renderAsText={renderAsText}
        currency={currency}
      />
    );
  } else {
    return (
      <ListingsSearchItemPrice
        amount={listing.bookable_nightly_price}
        translate={translate}
        renderAsText={renderAsText}
        currency={currency}
      />
    );
  }
};

const renderMobileCheckoutSteps = (activeStep, stepLength) => {
  switch (activeStep) {
    case 'stepFour':
      return `4 / ${stepLength}`;
    case 'stepThree':
      return `3 / ${stepLength}`;
    case 'stepTwo':
      return `2 / ${stepLength}`;
    default:
      return `1 / ${stepLength}`;
  }
};

const unflatten = items => {
  const topLevel = items
    .filter(item => !item.parent_id)
    .sort((a, b) => a.position - b.position);
  const childLevel = items
    .filter(item => item.parent_id)
    .sort((a, b) => a.position - b.position);
  const finalArray = topLevel.map(link => {
    const hasChildLinks = childLevel.filter(
      child => link.id === child.parent_id
    );
    return hasChildLinks.length > 0
      ? {
          ...link,
          subLinks: [...hasChildLinks]
        }
      : link;
  });

  return finalArray;
};

export {
  addCommas,
  formatCurrency,
  getAverage,
  isNumber,
  renderPrice,
  renderMobileCheckoutSteps,
  unflatten
};
