import React, { useState } from 'react';
import {
  Box,
  FlexBox,
  FormField,
  Label,
  Spacer,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import Geosuggest from 'react-geosuggest';
import { useSelector } from 'react-redux';
import { selectCheckoutState } from '../../../redux/slices/checkout';

const ContactAddress = ({
  addAction,
  headerText,
  updateCheckoutState,
  stateObject,
  triggerFilterChange
}) => {
  const [adrGeoSuggest, setAdrGeoSuggest] = useState(null);
  const [localInputFocused, setLocalInputFocused] = useState(false);
  const checkoutState = useSelector(selectCheckoutState);

  const isError = key => {
    if (checkoutState.lynnbrookErrors?.length) {
      if (checkoutState.lynnbrookErrors.includes(key)) return true;
    }

    return false;
  };

  const findAddressPart = (adrComponents, adr_part) => {
    return adrComponents.find(e => {
      return e.types[0] === adr_part;
    });
  };

  const updateOnSuggestSelected = suggest => {
    try {
      const adrComponents = suggest.gmaps.address_components;
      const adrPostalCode = findAddressPart(adrComponents, 'postal_code');
      const adrCountry = findAddressPart(adrComponents, 'country');
      const adrState = findAddressPart(
        adrComponents,
        'administrative_area_level_1'
      );
      const adrCity = findAddressPart(adrComponents, 'locality');
      const adrStreetName = findAddressPart(adrComponents, 'route');
      const adrStreetNumber = findAddressPart(adrComponents, 'street_number');
      const finalStreet = `${
        adrStreetNumber ? adrStreetNumber.long_name : ''
      } ${adrStreetName ? adrStreetName.long_name : ''}`;

      updateCheckoutState(
        {
          adrStreet: finalStreet,
          adrUnit: '',
          adrCity: adrCity ? adrCity.long_name : '',
          adrState: adrState ? adrState.long_name : '',
          adrCountry: adrCountry ? adrCountry.long_name : '',
          adrPostalCode: adrPostalCode ? adrPostalCode.long_name : '',
          adrCountryShort: adrCountry ? adrCountry.short_name : ''
        },
        triggerFilterChange
      );

      adrGeoSuggest.update(finalStreet);
    } catch (e) {
      updateCheckoutState({
        adrStreet: ''
      });
    }
  };

  const updateOnChange = e => {
    const stateChange = {};
    stateChange[e.target.name] = e.target.value;
    updateCheckoutState(stateChange, triggerFilterChange);
  };

  return (
    <>
      <FlexBox justifyContent="space-between" alignItems="center">
        <TextH2>{headerText || 'Contact Address'}</TextH2>
        {addAction}
      </FlexBox>
      <Spacer size="s" />
      <Spacer size="xxs" />
      <FlexBox gap="s">
        <Box flex="3">
          <Label>Address</Label>
          <Geosuggest
            placeholder="123 Road St."
            types={['geocode']}
            suggestsHiddenClassName="hidden"
            initialValue={stateObject.adrStreet}
            ref={node => {
              setAdrGeoSuggest(node);
            }}
            onSuggestSelect={updateOnSuggestSelected}
            className={`geoSuggest__textInputWrapper${
              localInputFocused ? ' focused' : ''
            }${isError('adrStreet') ? ' isError' : ''}`}
            inputClassName="geoSuggest__textInput"
            suggestsClassName="geoSuggest__suggestsWrapper"
            suggestItemClassName="geoSuggest__suggestItem"
            onFocus={() => setLocalInputFocused(true)}
            onBlur={() => setLocalInputFocused(false)}
          />
        </Box>
        <Box>
          <FormField
            labelText="Apt. Suite, etc."
            labelHtmlFor="adrUnit"
            inputType="text"
            inputProps={{
              name: 'adrUnit',
              placeholder: 'Ste. 1000',
              onChange: e => updateOnChange(e),
              inputWidth: 'auto',
              value: stateObject.adrUnit
            }}
          />
        </Box>
      </FlexBox>
      <FlexBox gap="s">
        <Box flex="2">
          <FormField
            labelText="City"
            labelHtmlFor="adrCity"
            inputType="text"
            inputProps={{
              name: 'adrCity',
              placeholder: 'Chicago',
              onChange: e => updateOnChange(e),
              value: stateObject.adrCity,
              isError: isError('adrCity')
            }}
          />
        </Box>
        <Box flex="1">
          <FormField
            labelText="State/Province"
            labelHtmlFor="adrState"
            inputType="text"
            inputProps={{
              name: 'adrState',
              placeholder: 'IL',
              onChange: e => updateOnChange(e),
              value: stateObject.adrState,
              isError: isError('adrState')
            }}
          />
        </Box>
        <Box flex="1">
          <FormField
            labelText="Postal Code"
            labelHtmlFor="adrPostalCode"
            inputType="text"
            inputProps={{
              name: 'adrPostalCode',
              placeholder: '60622',
              onChange: e => updateOnChange(e),
              value: stateObject.adrPostalCode,
              isError: isError('adrPostalCode')
            }}
          />
        </Box>
      </FlexBox>
      <FormField
        labelText="Country"
        labelHtmlFor="adrCountry"
        inputType="text"
        inputProps={{
          name: 'adrCountry',
          placeholder: '60622',
          onChange: e => updateOnChange(e),
          value: stateObject.adrCountry || 'United States',
          inputWidth: 'm',
          isError: isError('adrCountry')
        }}
      />
      <Spacer size="l" />
    </>
  );
};

export default ContactAddress;
