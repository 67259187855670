import React from 'react';
import {
  Box,
  CallToActionLink,
  Container,
  Divider,
  FlexBox,
  IconFontAwesome,
  IconLink,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Link from '../links/link';
import { useDetectMobile } from '../../../shared/hooks/useDetectMobile';
import NavSidebar from './nav-sidebar';

const HeaderBookingEngine = props => {
  const { isMobile } = useDetectMobile();
  const brand = useSelector(state => state.brand);
  const location = useLocation();

  const tempGuestSpacesUrl = () => {
    /**
     * This is a temporary fix to set a default zoom for Guestspaces
     */
    return brand.organization.id === 1037
      ? '/listings/search?amenities=&geo-ne-lat=31.61388993175555&geo-ne-lon=-96.93111842041104&geo-sw-lat=28.813663930487607&geo-sw-lon=-98.72463648681729&guests=1&sort=default&zoom=9'
      : '/listings/search';
  };

  const buildHomeLink = () => {
    if (brand.logo_image) {
      const logoImage = brand.logo_image.split('?');
      return (
        <img
          src={logoImage[0]}
          alt={brand.name}
          className="navigationBar__logoImg"
        />
      );
    } else {
      return <TextH2 truncate>{brand.name}</TextH2>;
    }
  };

  return (
    <>
      <Container variation="fluid-wls">
        <FlexBox
          className="navigationBar"
          justifyContent="space-between"
          alignItems="center"
          gap="s"
        >
          <Box style={{ minWidth: 0 }}>
            <Link to="/">{buildHomeLink()}</Link>
          </Box>
          <FlexBox alignItems="center" gap="xs">
            {location.pathname !== '/listings/search' && (
              <>
                {isMobile ? (
                  <IconLink
                    href={tempGuestSpacesUrl()}
                    size="dense"
                    customButtonColor={brand.brand_info.colors?.color_primary}
                    customTextColor={
                      brand.brand_info.colors?.color_primary_text
                    }
                  >
                    <IconFontAwesome name="search" />
                  </IconLink>
                ) : (
                  <CallToActionLink
                    href={tempGuestSpacesUrl()}
                    size="dense"
                    prependIcon={<IconFontAwesome name="search" />}
                    customButtonColor={brand.brand_info.colors?.color_primary}
                    customTextColor={
                      brand.brand_info.colors?.color_primary_text
                    }
                  >
                    Search
                  </CallToActionLink>
                )}
              </>
            )}
            <NavSidebar />
          </FlexBox>
        </FlexBox>
      </Container>
      <Divider />
    </>
  );
};

export default HeaderBookingEngine;
