import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
  Box,
  FlexBox,
  IconFontAwesome,
  Spacer,
  TextBody,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  getListingImageUrl,
  getStringifiedQueryString,
  smallImageDefault
} from '../resources/shared-functions';
import { selectListingsSearchState } from '../../../redux/slices/listingsSearch';
import { updateUiSlice } from '../../../redux/slices/ui';
import { renderPrice } from '../../../../shared/helpers';

const ListingsSearchGridItem = ({ listing, translate }) => {
  const swiperRef = useRef(null);
  const dispatch = useDispatch();
  const searchState = useSelector(selectListingsSearchState);
  const isRvFleet = useSelector(state => state.brand.isRvFleet);
  const { currency } = useSelector(state => state.brand);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [href, setHref] = useState('/');

  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slideNext();
  }, []);

  const renderImages = () => {
    if (!getListingImageUrl(listing)) {
      return (
        <a
          href={href}
          target="_blank"
          className="searchGridItem__link"
          rel="noreferrer"
        >
          <Box className="searchGridItem__noImage">
            <IconFontAwesome name="image" />
          </Box>
        </a>
      );
    } else {
      let allImages = [];
      if (listing.featured_image) {
        allImages.push(smallImageDefault(listing.featured_image));
        if (listing.all_images) {
          listing.all_images
            .filter(img => img.id !== listing.featured_image.id)
            .forEach(img => allImages.push(smallImageDefault(img)));
        }
      }
      allImages = allImages.slice(0, 5);

      return allImages.length > 1 ? (
        <>
          {currentSlide > 0 && (
            <FlexBox
              renderAs="button"
              onClick={handlePrev}
              className="searchGridItem__swiper_control -prev"
              justifyContent="center"
              alignItems="center"
            >
              <IconFontAwesome name="chevronLeft" />
            </FlexBox>
          )}
          {currentSlide < allImages.length - 1 && (
            <FlexBox
              renderAs="button"
              onClick={handleNext}
              className="searchGridItem__swiper_control -next"
              justifyContent="center"
              alignItems="center"
            >
              <IconFontAwesome name="chevronRight" />
            </FlexBox>
          )}
          <FlexBox
            className="searchGridItem__swiper_pagination"
            alignItems="center"
            gap="xxs"
          >
            {allImages.map((img, idx) => (
              <Box
                key={`dot-${idx}`}
                className={`searchGridItem__swiper_pagination_dot${
                  idx === currentSlide ? ' -active' : ''
                }`}
              />
            ))}
          </FlexBox>
          <Box className="searchGridItem__swiper">
            <Swiper
              ref={swiperRef}
              onSlideChange={swiper => {
                setCurrentSlide(swiper.activeIndex);
              }}
            >
              {allImages.map((img, idx) => (
                <SwiperSlide key={`slide-${idx}`}>
                  <a
                    href={href}
                    target="_blank"
                    className="searchGridItem__link"
                    rel="noreferrer"
                  />
                  <img
                    className="searchGridItem__image"
                    alt=""
                    src={img.image.small.url}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </>
      ) : (
        <>
          <a
            href={href}
            target="_blank"
            className="searchGridItem__link"
            rel="noreferrer"
          />
          <img
            className="searchGridItem__image"
            alt=""
            src={getListingImageUrl(listing)}
          />
        </>
      );
    }
  };

  useEffect(
    () => {
      setHref(
        listing.room_type_id
          ? `/listings/${listing.slug}/unit/${`${listing.default_unit_id +
              getStringifiedQueryString(searchState)}&room=${
              listing.room_type_id
            }`}`
          : `/listings/${listing.slug + getStringifiedQueryString(searchState)}`
      );
    },
    [listing]
  );

  const bedroomDetails = () => {
    if (isRvFleet) {
      return (
        <TextDense isFullWidth truncate>
          {`${translate(`global.vehicle_type.${listing.vehicle_type}`)} • ${
            listing.num_sleep_in_beds
          } guests, ${listing.num_bedrooms} bed`}
        </TextDense>
      );
    } else {
      return (
        <TextDense isFullWidth truncate>
          {listing.property?.multi_unit
            ? `${translate(
                `global.property_type.${listing.property.property_type}`
              )}`
            : `${translate(
                `global.property_type.${listing.property.property_type}`
              )} • ${listing.listings[0].unit?.num_sleep} guests, ${
                listing.listings[0].unit?.num_bedrooms
              } bed, ${listing.listings[0].unit?.num_bathrooms} bath`}
        </TextDense>
      );
    }
  };

  return (
    <FlexBox
      flexDirection="column"
      className="searchGridItem"
      setPositionRelative
      onMouseOver={() => {
        dispatch(updateUiSlice({ highlightMapMarkerId: listing.property.id }));
      }}
      onMouseLeave={() => {
        dispatch(updateUiSlice({ highlightMapMarkerId: null }));
      }}
    >
      {listing.featured && (
        <Box className="searchGridItem__featured">
          {translate(`cx.global.listing.featured.single`)}
        </Box>
      )}
      {listing.instant_booking && searchState.datesSet && (
        <Box className="searchGridItem__instant">
          <IconFontAwesome name="bolt" color="white" />
        </Box>
      )}
      <FlexBox className="searchGridItem__imageWrapper" setPositionRelative>
        {renderImages()}
      </FlexBox>
      <Box setPositionRelative>
        <a
          href={href}
          target="_blank"
          className="searchGridItem__link"
          rel="noreferrer"
        />
        <Spacer size="xs" />
        <FlexBox justifyContent="space-between" gap="s">
          <TextBody
            className="searchGridItem__name"
            textColor="dark-gray"
            weight="semibold"
            truncate
          >
            {listing.name}
          </TextBody>
          {listing.review_count > 0 && (
            <FlexBox gap="xxs" alignItems="center">
              <Box className="searchGridItem__ratingIcon">
                <IconFontAwesome name="star" />
              </Box>
              <TextBody weight="semibold">{listing.review_average}</TextBody>
            </FlexBox>
          )}
        </FlexBox>
        <Spacer size="xxs" />
        <TextDense isFullWidth truncate>
          {`${listing.location.adr_city}, ${listing.location.adr_state}`}
        </TextDense>
        {bedroomDetails()}
        <Spacer size="xs" />
        {renderPrice(listing, false, currency)}
      </Box>
    </FlexBox>
  );
};

export default ListingsSearchGridItem;
