import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import InjectedCardSetupFormV2 from './card-setup-form-v2';

let stripeToken;
const stripePromise = props => {
  if (!stripeToken) {
    stripeToken = props.stripeAccountID
      ? loadStripe(props.stripePublishableKey, {
          stripeAccount: props.stripeAccountID
        })
      : loadStripe(props.stripePublishableKey);
  }
  return stripeToken;
};

const StripeV2 = props => {
  return (
    <Elements stripe={stripePromise(props)}>
      <InjectedCardSetupFormV2
        addonFeeIds={props.addonFeeIds}
        availability={props.availability}
        booking={props.booking}
        bookingDaysInclusive={props.bookingDaysInclusive}
        brand_info={props.brand_info}
        brand={props.brand}
        chargeAmount={props.chargeAmount}
        checkInDate={props.checkInDate}
        checkOutDate={props.checkOutDate}
        couponCode={props.couponCode}
        currency={props.currency}
        customerEmail={props.customerEmail}
        customerName={props.customerName}
        customerPostalCode={props.customerPostalCode}
        customerTelephone={props.customerTelephone}
        adrStreet={props.adrStreet}
        adrUnit={props.adrUnit}
        adrCity={props.adrCity}
        adrState={props.adrState}
        adrCountry={props.adrCountry}
        adrPostalCode={props.adrPostalCode}
        adrCountryShort={props.adrCountryShort}
        guests={props.guests}
        listing={props.listing}
        max_guests={props.max_guests}
        pricing={props.pricing}
        quoteId={props.quoteId}
        rental_agreement={props.rental_agreement}
        slug={props.slug}
        stripeCustomerId={props.stripeCustomerId}
        stripeIntentId={props.stripeIntentId}
        translate={props.translate}
        unit={props.unit}
        updateGuests={props.updateGuests}
        verifyImage={props.verifyImage}
        verifySignature={props.verifySignature}
        verifyAge={props.verifyAge}
        verifyAddress={props.verifyAddress}
        urlLocation={props.urlLocation}
        deliveryLocation={props.deliveryLocation}
        validationErrors={props.validationErrors}
      />
    </Elements>
  );
};

export default StripeV2;
